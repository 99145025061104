import React from "react";
import { Link } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

const NotFound = () => (
  <div className="flex flex-col justify-center align-center h-full gap-2 bg-[#868686]">
    <div className="text-4xl font-serif text-white text-center"></div>
    <span className="font-serif text-7xl text-black text-center">
      4
      <SentimentVeryDissatisfiedIcon
        htmlColor="black"
        style={{ width: "85px", height: "85px" }}
      />
      4
    </span>
    <div className="text-4xl font-serif text-white text-center">
      Oops, Something Went Wrong...
    </div>
    <span className="text-2xl font-serif  text-white text-center">
      Please Click here to{" "}
      <Link to="/sales" className="text-[#c19b3b] underline">
        {" "}
        Home
      </Link>
    </span>
  </div>
);

export default NotFound;
