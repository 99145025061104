import { orderBy, process } from "@progress/kendo-data-query";
import { Children, cloneElement, useEffect, useRef, useState } from "react";
import { CustomNoRowsOverlay } from "./Overlay";
import {
  Grid,
  GridColumn as Column,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { filterBy } from "@progress/kendo-data-query";
import { SvgIcon } from "@progress/kendo-react-common";
import * as svgIcons from "@progress/kendo-svg-icons";
import { Popup } from "@progress/kendo-react-popup";
import { Error } from "@progress/kendo-react-labels";
import { CrudButtons } from "./CrudButtons";
import { connect } from "react-redux";
import moment from "moment";
import { IconButton } from "@mui/material";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Check, Close } from "@mui/icons-material";

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

const initialDataState = {
  skip: 0,
  take: 50,
};

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const CommonGrid = (props) => {
  const initialSort = [
    {
      field: props.DATA_ITEM_KEY,
      dir: "asc",
    },
  ];

  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [sort, setSort] = useState(initialSort);
  const [applyMinWidth, setApplyMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);
  const minGridWidth = useRef(0);

  const pageChange = (event) => {
    const targetEvent = event.targetEvent;
    const take = event.page.take;
    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const setWidth = (minWidth) => {
    if (minWidth === undefined) minWidth = 0;
    let width = applyMinWidth
      ? minWidth
      : minWidth + (gridCurrent - minGridWidth.current) / props.columns.length;
    if (width > COLUMN_MIN) {
      width -= ADJUST_PADDING;
    }
    return width;
  };

  return (
    <>
      {props.gridLoading && loadingPanel}
      <div
        className="h-full"
        style={{
          height: `${props.height ? "calc(100% - 45px)" : "100%"}`,
        }}
      >
        <Grid
          style={{
            height: "100%",
            headerClassName: { "background-color": "#666" },
          }}
          dataItemKey={props.DATA_ITEM_KEY}
          rowHeight={props.rowHeight ? props.rowHeight : 35}
          data={filterBy(
            orderBy(
              props.rowData.slice(page.skip, page.take + page.skip),
              sort
            ),
            props.filter
          )}
          sortable={true}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          skip={page.skip}
          take={page.take}
          total={props.rowData.length}
          pageable={{
            buttonCount: 4,
            pageSizes: [50, 100, 150, 200],
            pageSizeValue: pageSizeValue,
          }}
          onPageChange={pageChange}
          filterable={props.filterable}
          filter={props.filter}
          onFilterChange={(e) => props.setFilter(e.filter)}
        >
          <GridNoRecords>
            <CustomNoRowsOverlay />
          </GridNoRecords>
          {props.columns.map((column, index) => {
            if (column.field === "action") {
              console.log(props?.buttons, "buttons", column);
              return (
                <Column
                  key={index}
                  field={column.field}
                  title={column.headerName}
                  width={setWidth(column.minWidth)}
                  filterable={false}
                  cell={(dataItem) => {
                    return (
                      <td className="k-command-cell">
                        {props?.buttons?.map((each) => {
                          return (
                            <IconButton onClick={() => each.onClick(dataItem)}>
                              {each.button}
                            </IconButton>
                          );
                        })}
                      </td>
                    );
                  }}
                />
              );
            }
            return (
              <Column
                key={index}
                field={column.field}
                title={column.headerName}
                filter={column.filter}
                filterable={column.filterable}
                width={setWidth(column.minWidth)}
                cell={(cellItem) => {
                  let cellData = cellItem.dataItem[column.field];
                  return (
                    <td className="k-command-cell">
                      {column.format === "date" ? (
                        moment(cellData).format("MM-DD-YYYY")
                      ) : column.format === "boolean" ? (
                        cellData ? (
                          <Check color="success" />
                        ) : (
                          <Close color="error" />
                        )
                      ) : (
                        cellData
                      )}
                    </td>
                  );
                }}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(CommonGrid);

const CustomCell = (props) => {
  const [focused, setFocused] = useState(false);
  const anchor = useRef(null);
  const isValid = props.dataItem[props.field + "Valid"];

  console.log(
    "celllll",
    props.dataItem,
    props.dataItem.isPrimary,
    props.dataItem.id,
    props
  );
  return props.tdProps ? (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {Children.toArray(props.children).map((child) => {
          return cloneElement(child, {
            className:
              child.props.className +
              (isValid ? " k-input-solid k-invalid" : ""),
          });
        })}
      </td>
      {props.dataItem.inEdit && focused && (
        <Popup
          anchor={anchor.current}
          show={!!isValid}
          popupClass={"popup-content"}
          style={{ zIndex: "11111" }}
        >
          <Error>{isValid}</Error>
        </Popup>
      )}
    </>
  ) : null;
};

const MyInputCustomCell = (props) => (
  <>
    <CustomCell {...props} />
  </>
);

const CustomDropDownCell = (props, column) => {
  const [focused, setFocused] = useState(false);
  const anchor = useRef(null);
  const isValid = props.dataItem[props.field + "Valid"];
  console.log(props, "propssssss", column);

  return props.tdProps ? (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        <DropDownList
          data={column.data}
          className={`h-[1.6rem] m-0 bg-white hover:bg-white focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1`}
          value={
            props.dataItem[props.field] !== null
              ? props.dataItem[props.field]
              : "Please Select..."
          }
          onChange={(e) => props?.children?.props?.onChange(e)}
          defaultValue="Please Select..."
        />
      </td>
      {props.dataItem.inEdit && focused && (
        <Popup
          anchor={anchor.current}
          show={!!isValid}
          popupClass={"popup-content"}
        >
          <Error>{isValid}</Error>
        </Popup>
      )}
    </>
  ) : null;
};

const MyCustomCell = (props, column) => {
  const anchor = useRef(null);
  console.log(props.dataItem, props.field, props, "dataItem", column);
  return (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        className={" k-table-td "}
      >
        {column.editor === "boolean" ? (
          props.dataItem[props.field] === true ? (
            <Check color="success" />
          ) : (
            <Close color="error" />
          )
        ) : column.editor === "date" ? (
          moment(props.dataItem[props.field]).format("MM/DD/YYYY")
        ) : (
          props.dataItem[props.field]
        )}
      </td>
    </>
  );
};

export const EditableGrid = (props) => {
  const initialDataState = {
    sort: [
      {
        field: props.DATA_ITEM_KEY,
        dir: "asc",
      },
    ],
    take: 50,
    skip: 0,
  };

  const [pageSizeValue, setPageSizeValue] = useState();
  const minGridWidth = useRef(0);
  const [applyMinWidth, setApplyMinWidth] = useState(false);
  const [gridCurrent, setGridCurrent] = useState(0);
  const [dataState, setDataState] = useState(initialDataState);
  const [dataResult, setDataResult] = useState(
    process(props.rowData, dataState)
  );

  useEffect(() => {
    if (!props.addDisable) {
      setDataResult(process(props.rowData, dataState));
    } else {
      let tempDataState = {
        filter: undefined,
        group: undefined,
        sort: undefined,
        take: 50,
        skip: 0,
      };
      setDataResult(process(props.rowData, tempDataState));
      console.log("dataState", dataState);
    }
  }, [props.rowData]);

  const setWidth = (minWidth) => {
    if (minWidth === undefined) minWidth = 0;
    let width = applyMinWidth
      ? minWidth
      : minWidth + (gridCurrent - minGridWidth.current) / props.columns.length;
    if (width > COLUMN_MIN) {
      width -= ADJUST_PADDING;
    }
    return width;
  };

  console.log(props.rowData, "rows", props.DATA_ITEM_KEY, props, dataResult);

  return (
    <>
      {props.gridLoading && loadingPanel}
      <div
        style={{
          height: `calc(100% - ${props.height ? props.height : "40px"})`,
        }}
      >
        <Grid
          pageable={{
            buttonCount: 4,
            pageSizes: [50, 100, 150, 200],
            pageSizeValue: pageSizeValue,
          }}
          sortable={!props.addDisable}
          style={{
            height: "100%",
            headerClassName: { "background-color": "#666" },
          }}
          rowHeight={props.rowHeight ? props.rowHeight : 50}
          data={dataResult}
          {...dataState}
          onDataStateChange={(e) => {
            setDataState(e.dataState);
            setDataResult(process(props.rowData, e.dataState));
          }}
          editField={"inEdit"}
          onItemChange={props.itemChange}
          cells={{
            edit: {
              text: MyInputCustomCell,
            },
          }}
        >
          <GridNoRecords>
            <CustomNoRowsOverlay />
          </GridNoRecords>
          {props.columns.map((column, index) => {
            console.log(props?.buttons, "buttons", column);
            if (column.field === "actions") {
              return (
                <Column
                  key={index}
                  field={column.field}
                  title={column.headerName}
                  format={column.format}
                  width={setWidth(column.minWidth)}
                  cell={(dataItem) =>
                    props.isCrud ? (
                      <CrudButtons
                        dataItem={dataItem.dataItem}
                        DATA_ITEM_KEY={props.DATA_ITEM_KEY}
                        setData={props.setData}
                        data={props.rowData}
                        orgGridData={props.orgGridData}
                        setAddDisable={props.setAddDisable}
                        addDisable={props.addDisable}
                        handleDelete={props.handleDelete}
                        handleAdd={props.handleAdd}
                        handleUpdate={props.handleUpdate}
                        handleSetPrimary={props.handleSetPrimary}
                        addBotton={props.addBotton}
                        columns={props.columns}
                      />
                    ) : (
                      props?.buttons?.map((each) => {
                        if (each.isVisible) {
                          return (
                            <button
                              onClick={() => each.onClick(dataItem.dataItem)}
                              className="mx-2"
                            >
                              <SvgIcon
                                icon={svgIcons[each.icon]}
                                size="medium"
                                themeColor="primary"
                              />
                            </button>
                          );
                        }
                      })
                    )
                  }
                />
              );
            }
            if (column.editor === "dropDown") {
              return (
                <Column
                  key={index}
                  field={column.field}
                  title={column.headerName}
                  format={column.format}
                  width={setWidth(column.minWidth)}
                  // cells={{text: <MySelectedCustomCell/> }}
                  cells={{
                    edit: {
                      text: (props) => CustomDropDownCell(props, column),
                    },
                  }}
                />
              );
            }
            if (column.editor === "boolean") {
              return (
                <Column
                  key={index}
                  field={column.field}
                  title={column.headerName}
                  format={column.format}
                  width={setWidth(column.minWidth)}
                  cells={{
                    data: (props) => MyCustomCell(props, column),
                  }}
                  editable={false}
                />
              );
            }
            return (
              <Column
                key={index}
                field={column.field}
                title={column.headerName}
                width={setWidth(column.minWidth)}
                editable={column?.inEdit ? column.inEdit : false}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
};

EditableGrid.defaultProps = {
  DATA_ITEM_KEY: "",
  columns: [],
  url: "",
  rowHeight: 50,
  rowData: [],
  primaryKeyID: "",
};
