import { Button, Stack, Typography } from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import {
  handleFormValidation,
  viewErrorMessage,
} from "../../CommonFunctions/CommonMethods";
import { AntSwitch } from "../../MasterComponent/Overlay";
import {
  addPointOfContact,
  setHasSeenProgramDescription,
} from "../../CommonFunctions/ApiCalls";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { fields } from "../../MasterComponent/CommonConstants";

const BackUp = (props) => {
  const [keysystem, setKeysystem] = useState({});
  const [agreement, setAgreement] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [disableFields, setDisableFields] = useState({
    agreement: false,
    keysystem: false,
  });

  useEffect(() => {
    if (props.userRole.role === "end-user") {
      setIsButtonDisable(
        (!disableFields.agreement &&
          !disableFields.keysystem &&
          (Object.keys(keysystem)?.length !== 4 ||
            Object.keys(agreement)?.length !== 4)) ||
          (disableFields.agreement &&
            !disableFields.keysystem &&
            Object.keys(keysystem)?.length !== 4) ||
          (!disableFields.agreement &&
            disableFields.keysystem &&
            Object.keys(agreement)?.length !== 4) ||
          errorMessage?.length > 0
      );
    } else {
      setIsButtonDisable(
        !(
          (Object.keys(agreement).length === 4 && errorMessage.length === 0) ||
          disableFields.agreement
        )
      );
    }
  }, [agreement, disableFields, keysystem]);

  const navigate = useNavigate();

  let agreementId = useParams("agreementId");

  const handleOnChange = (fieldKey, e) => {
    console.log(fieldKey, "fieldKey");

    let updateAgreementSign = { ...agreement };
    let updateKeysystem = { ...keysystem };
    fieldKey.includes("agreement")
      ? (updateAgreementSign[fieldKey] = e.target.value)
      : (updateKeysystem[fieldKey] = e.target.value);
    fieldKey.includes("agreement")
      ? setAgreement(updateAgreementSign)
      : setKeysystem(updateKeysystem);
    let errors = handleFormValidation(fieldKey, e.target.value);
    setErrorMessage(errors);
  };

  const handleFormValidation = (fieldName, value) => {
    let validationFaildMessage =
      errorMessage && errorMessage.length > 0 ? [...errorMessage] : [];
    if (!value) {
      if (
        fieldName.includes("EmailAddress") ||
        fieldName.includes("PhoneNumber")
      ) {
        if (
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ]
        ) {
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ] = {
            fieldName: fieldName,
            errorMessage: `Please enter ${
              fields.backup.find((each) => each[fieldName])[fieldName]
            }`,
          };
        } else {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please enter ${
              fields.backup.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
      } else {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please enter ${
            fields.backup.find((each) => each[fieldName])[fieldName]
          }`,
        });
      }
    } else {
      if (value) {
        validationFaildMessage = validationFaildMessage.filter(
          (error) => error.fieldName !== fieldName
        );
        if (fieldName.includes("EmailAddress") && !/\S+@\S+\.\S+/.test(value)) {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please enter valid ${
              fields.backup.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
        if (fieldName.includes("PhoneNumber") && !/^(\d+-?)+\d+$/.test(value)) {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please enter valid ${
              fields.backup.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
      }
    }
    return validationFaildMessage;
  };

  const getInputType = (fieldkey, field) => {
    return (
      <div className="col-span-2">
        <input
          type="text"
          className={`border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-80 text-size ${
            disableFields[field] && "cursor-not-allowed text-gray-400"
          }`}
          onChange={(e) => {
            handleOnChange(fieldkey, e);
          }}
          value={
            field === "agreement"
              ? !disableFields?.agreement
                ? agreement && agreement[fieldkey]
                : ""
              : !disableFields?.keysystem
              ? keysystem && keysystem[fieldkey]
              : ""
          }
          disabled={disableFields[field]}
        />
        <span className="text-xs text-red-500 w-full">
          {!disableFields[field] && viewErrorMessage(fieldkey, errorMessage)}
        </span>
      </div>
    );
  };

  const handelSwitchChange = (e, field) => {
    let checked = e.target.checked;
    let fieldDisable = { ...disableFields };
    fieldDisable[field] = checked;
    setDisableFields(fieldDisable);
  };

  const setSeenProgramDescription = async () => {
    await setHasSeenProgramDescription(agreementId.agreementId)
      .then(async (result) => {
        if (result && result.statusCode === 200) {
          var nav = isButtonDisable
            ? ""
            : `/${props.userRole.role}/agreement/${agreementId.agreementId}`;
          navigate(nav);
        } else {
          toast.error("Something went wrong Please try again later !!!");
        }
      })
      .catch((e) => {
        toast.error("Something went wrong Please try again later !!!");
        console.log(e, "err");
      });
  };
  console.log(agreement, keysystem, "pointOfContact");
  const handleAddPointOfContact = async (pointOfContact, field) => {
    console.log(pointOfContact, agreement, keysystem, "pointOfContact");

    let requestBody = {
      fk_AgreementId: agreementId.agreementId,
      firstName:
        field === "agreement"
          ? pointOfContact["agreementFirstName"]
          : pointOfContact["keysystemFirstName"],
      lastName:
        field === "agreement"
          ? pointOfContact["agreementLastName"]
          : pointOfContact["keysystemLastName"],
      emailAddress:
        field === "agreement"
          ? pointOfContact["agreementEmailAddress"]
          : pointOfContact["keysystemEmailAddress"],
      phoneNumber:
        field === "agreement"
          ? pointOfContact["agreementPhoneNumber"]
          : pointOfContact["keysystemPhoneNumber"],
      isPrimary: false,
    };
    let result = await addPointOfContact(requestBody);
    console.log(result, "add point of contact");
    if (result.ok && result.statusCode === 200) {
      return true;
    } else {
      return false;
    }
  };

  const handleNext = async () => {
    let flag = true;
    if (!disableFields.agreement && flag) {
      flag = await handleAddPointOfContact(agreement, "agreement");
    }
    if (
      !disableFields.keysystem &&
      flag &&
      props.userRole.role !== "customer"
    ) {
      flag = await handleAddPointOfContact(keysystem, "keysystem");
      console.log(flag, "flag");
    }
    if (flag) {
      setSeenProgramDescription();
    }
  };

  return (
    <>
      <div className="flex gap-5">
        <label className="font-semibold">
          Will you be signing the agreement?
        </label>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>No</Typography>
          <AntSwitch
            onChange={(e) => handelSwitchChange(e, "agreement")}
            inputProps={{ "aria-label": "ant design" }}
          />
          <Typography>Yes</Typography>
        </Stack>
      </div>
      <div className="grid grid-cols-3 gap-3 w-1/2 m-3">
        <label className="text-size">
          First Name<span className="text-red-500"> *</span>
        </label>
        {getInputType("agreementFirstName", "agreement")}
        <div className="text-size">
          Last Name<span className="text-red-500"> *</span>
        </div>
        {getInputType("agreementLastName", "agreement")}
        <label className="text-size">
          Email Address<span className="text-red-500"> *</span>
        </label>
        {getInputType("agreementEmailAddress", "agreement")}
        <label className="text-size">
          Phone #<span className="text-red-500"> *</span>
        </label>
        {getInputType("agreementPhoneNumber", "agreement")}
      </div>
      {props.userRole.role !== "customer" && (
        <>
          <div className="my-3 flex gap-5">
            <label className="font-semibold">
              Will you be designing the keysystem?
            </label>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <AntSwitch
                onChange={(e) => handelSwitchChange(e, "keysystem")}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </div>
          <div className="grid grid-cols-3 gap-3 w-1/2 m-3">
            <label className="text-size">
              First Name<span className="text-red-500"> *</span>
            </label>
            {getInputType("keysystemFirstName", "keysystem")}
            <div className="text-size">
              Last Name<span className="text-red-500"> *</span>
            </div>
            {getInputType("keysystemLastName", "keysystem")}
            <label className="text-size">
              Email Address<span className="text-red-500"> *</span>
            </label>
            {getInputType("keysystemEmailAddress", "keysystem")}
            <label className="text-size">
              Phone #<span className="text-red-500"> *</span>
            </label>
            {getInputType("keysystemPhoneNumber", "keysystem")}
          </div>
        </>
      )}

      <div className="text-center p-5">
        <Button
          variant="contained"
          size="small"
          className="caps-text-size button-text-decor"
          endIcon={<NavigateNextIcon />}
          disabled={isButtonDisable}
          style={
            isButtonDisable ? { cursor: "not-allowed" } : { cursor: "pointer" }
          }
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, null)(BackUp);
