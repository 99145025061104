import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import CommonGrid, { EditableGrid } from "../../MasterComponent/CommonGrid";
import {
  keyScheduleField,
  keyScheduleInternal,
} from "../../MasterComponent/CommonConstants";
import {
  addKeyingScheduleLine,
  deleteKeyingScheduleLine,
  getAgreementStatus,
  getFinishOption,
  getKeyingSchedule,
  getResources,
  keyingScheduleBulkUpload,
  updateKeyingScheduleLine,
} from "../../CommonFunctions/ApiCalls";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmBox from "../../MasterComponent/ConfirmBox";
import {
  handleDownload,
  requiredValidator,
} from "../../CommonFunctions/CommonMethods";
import { toast } from "react-toastify";
import { SetLoadingOverlay } from "../../Redux/Action";
import { connect } from "react-redux";
import KeyIcon from "@mui/icons-material/Key";
import * as XLSX from "xlsx";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ReplyIcon from "@mui/icons-material/Reply";

const KeyingSchedule = (props) => {
  const DATA_ITEM_KEY = "id";
  const editFieldContact = "inEdit";

  const [rows, setRows] = useState([]);
  const [keyScheduleData, setKeyScheduleData] = useState([]);
  const [gridLoading, setGridLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [jsonData, setJsonData] = useState("");
  const [isOpen, setIsOpen] = useState({ open: false, type: null });
  const [quantity, setQuantity] = useState({ doorQty: 0, keyQty: 0 });
  const [addDisable, setAddDisable] = useState(false);
  const [resource, setResource] = useState({});
  const [filter, setFilter] = useState();
  const [finish, setFinish] = useState();
  const [agreementStatus, setAgreementStatus] = useState({});

  let agreementId = useParams("agreementId");
  const navigate = useNavigate();

  useEffect(() => {
    getKeySchedule();
    getResourcesApi();
    getFinishes();
    getAgreementStatusApi();
  }, []);

  useEffect(() => {
    if (rows) {
      console.log(rows, "grid data");
      let cylinderCount = 0;
      let keyCount = 0;
      rows?.length > 0 &&
        rows.map((each) => {
          cylinderCount = cylinderCount + each.cylinderQty;
          keyCount = keyCount + each.keyQuantity;
        });
      setQuantity({ doorQty: cylinderCount, keyQty: keyCount });
    }
  }, [rows]);

  const getKeySchedule = async () => {
    setGridLoading(true);
    await getKeyingSchedule(agreementId.agreementId)
      .then((data) => {
        console.log(data, "data1");
        if (data) {
          setRows(data);
          setKeyScheduleData(data);
        }
      })
      .catch((e) => console.log(e));
    setGridLoading(false);
  };

  const handleConvert = () => {
    if (uploadFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        console.log(json, "json");
        setJsonData(json);
        keyingScheduleBulkUploadApi(json);
      };
      reader.readAsBinaryString(uploadFile);
    }
  };

  console.log(uploadFile, "Reader", resource.url, finish);

  const itemChangeKeySchedule = (event) => {
    const field = event.field || "";
    console.log("newData", event);
    const newData = keyScheduleData.map((item) =>
      item[DATA_ITEM_KEY] === event.dataItem[DATA_ITEM_KEY]
        ? {
            ...item,
            [field]: event.value,
            [field + "Valid"]: requiredValidator(event.value, event.field),
          }
        : item
    );
    console.log(newData, "newData");
    setKeyScheduleData(newData);
  };

  const updateKeyingScheduleLineApi = async (request) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      agreementId: agreementId.agreementId,
      line: request.line,
      cylinderQty: parseInt(request.cylinderQty),
      doorNumber: request.doorNumber,
      partNumber: request.partNumber,
      finish: request.finish,
      keyQuantity: parseInt(request.keyQuantity),
      keyset: request.keyset,
    };
    await updateKeyingScheduleLine(requestBody)
      .then(async (result) => {
        if (result.statusCode === 200 && result.ok) {
          await getKeySchedule();
          toast.success("updated Successfully !!!");
        } else {
          toast.error("Please try again later!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const deleteKeyingScheduleLineApi = async (request) => {
    props.SetLoadingOverlay(true);
    await deleteKeyingScheduleLine(agreementId.agreementId, request.line)
      .then(async (result) => {
        console.log(result, "result");
        if (result.status === 0) {
          await getKeySchedule();
          toast.success("Deleted Successfully !!!");
        } else {
          toast.error("Please try again later!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    setKeyScheduleData([newDataItem, ...keyScheduleData]);
    setAddDisable(true);
  };

  const addKeyingScheduleLineApi = async (request) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      agreementId: agreementId.agreementId,
      cylinder_Qty: parseInt(request.cylinderQty),
      door_Number: request.doorNumber,
      part_Number: request.partNumber,
      finish: request.finish,
      key_Quantity: parseInt(request.keyQuantity),
      keyset: request.keyset,
    };
    await addKeyingScheduleLine(requestBody)
      .then(async (result) => {
        if (result.statusCode === 200 && result.ok) {
          await getKeySchedule();
          toast.success("Added Successfully !!!");
        } else {
          toast.error("Please try again later!!!");
        }
      })
      .catch((e) => {
        toast.error(e);
      });
    props.SetLoadingOverlay(false);
  };

  const keyingScheduleBulkUploadApi = (request) => {
    let tempRequest = [...request];
    let finishData = tempRequest.map((item) => item?.Finish);
    tempRequest.map((each) => {
      each["agreementId"] = agreementId.agreementId;
      if (each["Door_Number"] !== undefined && each["Door_Number"] !== null) {
        each["Door_Number"] = each["Door_Number"]?.toString();
      }
      each["Finish"] = each["Finish"]?.toString();
    });
    if (tempRequest.length === 0) {
      toast.error("Please Upload Valid template file with data");
      setUploadFile(null);
    } else if (
      tempRequest.length > 0 &&
      Object.keys(tempRequest[0]).length !== keyScheduleField.length
    ) {
      toast.error("Please Upload Valid template file with data");
      setUploadFile(null);
    } else if (!finishData.every((item) => item.toString().length <= 3)) {
      toast.error("Please Upload template file with valid Finish");
      setUploadFile(null);
    } else {
      let isFailed = false;
      keyScheduleField.map((each) => {
        if (!Object.keys(tempRequest[0]).includes(each) && !isFailed)
          isFailed = true;
      });
      console.log(isFailed, "isFailed");
      if (!isFailed) {
        setJsonData(tempRequest);
        if (rows && rows.length > 0) {
          setIsOpen({ open: true, type: "confirm" });
        } else {
          handleConfirmationBulkUpload(true, request);
        }
      } else {
        toast.error("Please Upload Valid template file with data");
        setUploadFile(null);
      }
    }
  };

  const handleConfirmationBulkUpload = async (overWriteFlag, request) => {
    props.SetLoadingOverlay(true);
    setIsOpen({ open: false, type: null });
    console.log(jsonData, "requested data");
    await keyingScheduleBulkUpload(JSON.stringify(request), overWriteFlag)
      .then(async (result) => {
        if (result && result.statusCode === 200) {
          await getKeySchedule();
          setUploadFile(null);
          toast.success("Added Successfully !!!");
        } else {
          setUploadFile(null);
          toast.error("Please try again later!!!");
        }
      })
      .catch((e) => {
        toast.error(e);
      });
    props.SetLoadingOverlay(false);
  };

  const getResourcesApi = async () => {
    // setGridLoading(true);
    await getResources(agreementId.agreementId)
      .then((data) => {
        if (data && data.length > 0) {
          setResource(data);
        }
      })
      .catch((e) => console.log(e));
    // setGridLoading(false);
  };

  const getFinishes = async () => {
    // setGridLoading(true);
    await getFinishOption()
      .then((data) => {
        if (data && data.length > 0) {
          setFinish(data);
        }
      })
      .catch((e) => console.log(e));
    // setGridLoading(false);
  };

  const getAgreementStatusApi = async () => {
    props.SetLoadingOverlay(true);
    await getAgreementStatus(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreementStatus(data);
        }
      })
      .catch((e) => console.log(e));
    props.SetLoadingOverlay(false);
  };

  const keySchedule = [
    {
      field: "line",
      headerName: "Line",
      minWidth: 100,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: false,
    },
    {
      field: "cylinderQty",
      headerName: "Cylinder Qty",
      minWidth: 100,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
    },
    {
      field: "doorNumber",
      headerName: "Door Number",
      minWidth: 140,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
    },
    {
      field: "partNumber",
      headerName: "Part Number",
      minWidth: 170,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
    },
    {
      field: "finish",
      headerName: "Finish",
      minWidth: 140,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
      data: finish,
      editor: "dropDown",
    },
    {
      field: "keyQuantity",
      headerName: "Key Qty",
      minWidth: 100,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
    },
    {
      field: "keyset",
      headerName: "Key Set",
      minWidth: 140,
      headerClassName: "bg-[#1222]",
      filterable: false,
      inEdit: true,
      required: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 140,
      locked: true,
    },
  ];

  return (
    <>
      <div className="flex justify-between">
        {props.userRole.role === "end-user" && !agreementStatus?.isFinished && (
          <div className="flex gap-3 items-center">
            {!uploadFile ? (
              <Button
                variant="contained"
                size="small"
                component="label"
                startIcon={<AttachFileIcon />}
                className="caps-text-size button-text-decor"
              >
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  onChange={(e) => setUploadFile(e.target.files[0])}
                  hidden
                />
                Select File
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                component="label"
                startIcon={<CloudUploadIcon />}
                className="caps-text-size button-text-decor"
                onClick={handleConvert}
              >
                Upload Keying Schedule
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              startIcon={<CloudDownloadIcon />}
              className="caps-text-size button-text-decor"
              onClick={() => handleDownload(resource, "keyingscheduletemplate")}
            >
              Download Template
            </Button>
          </div>
        )}

        <div className="flex gap-5 items-center pr-5">
          <label className="text-size">
            Cylinder Qty Total:{" "}
            <span className="font-bold">{quantity.doorQty}</span>{" "}
          </label>
          <label className="text-size">
            Key Qty Total: <span className="font-bold">{quantity.keyQty}</span>
          </label>
        </div>
        <div>
          {props.userRole.role === "end-user" &&
            !agreementStatus?.isFinished && (
              <Button
                variant="contained"
                size="small"
                startIcon={<KeyIcon />}
                onClick={() => addNew()}
                disabled={addDisable}
                className="caps-text-size button-text-decor"
              >
                Add keyschedule
              </Button>
            )}
          <Button
            variant="outlined"
            size="small"
            startIcon={<ReplyIcon />}
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={
              props.userRole.role === "end-user"
                ? () =>
                    navigate(
                      `/${props.userRole.role}/agreement/${agreementId.agreementId}`
                    )
                : () =>
                    navigate(
                      `/${props.userRole.role}/view-agreement/${agreementId.agreementId}`
                    )
            }
            className="caps-text-size button-text-decor"
          >
            Back
          </Button>
        </div>
      </div>
      {props.userRole.role === "end-user" && !agreementStatus?.isFinished ? (
        <>
          {uploadFile && (
            <div>
              <span className="text-sm text-blue-600">{uploadFile.name}</span>
              <IconButton size="small">
                <HighlightOffIcon
                  color="error"
                  fontSize="inherit"
                  onClick={() => setUploadFile(null)}
                />
              </IconButton>
            </div>
          )}
          <div className="mt-2" style={{ height: "calc(100% - 40px)" }}>
            <EditableGrid
              gridLoading={gridLoading}
              DATA_ITEM_KEY={DATA_ITEM_KEY}
              columns={keySchedule}
              height="0px"
              rowHeight={20}
              rowData={keyScheduleData}
              setData={setKeyScheduleData}
              itemChange={itemChangeKeySchedule}
              editField={editFieldContact}
              isCrud={true}
              orgGridData={rows}
              setAddDisable={setAddDisable}
              addDisable={addDisable}
              handleDelete={deleteKeyingScheduleLineApi}
              handleAdd={addKeyingScheduleLineApi}
              handleUpdate={updateKeyingScheduleLineApi}
              addBotton={false}
            />
            <ConfirmBox
              isOpen={isOpen.open && isOpen.type === "confirm"}
              onSubmitModel={() => handleConfirmationBulkUpload(true, jsonData)}
              onCloseModel={() => setIsOpen({ open: false, type: null })}
              message={"Do you want to overwrite the existing data?"}
              type="update"
              onCancel={() => handleConfirmationBulkUpload(false, jsonData)}
            />
          </div>
        </>
      ) : (
        <div className="mt-2" style={{ height: "calc(100% - 37px)" }}>
          <CommonGrid
            gridLoading={gridLoading}
            DATA_ITEM_KEY={DATA_ITEM_KEY}
            rowData={keyScheduleData}
            columns={keyScheduleInternal}
            filter={filter}
            setFilter={setFilter}
            filterable={false}
          />
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(KeyingSchedule);
