import "./App.css";
import Header from "./MasterComponent/Header";
import "./index.css";
import SideBar from "./MasterComponent/SideBar";
import { useNavigate } from "react-router-dom";
import Routing from "./MasterComponent/Routing";
import { useEffect, useState } from "react";
import Login from "./MasterComponent/Login";
import { connect } from "react-redux";
import { CommonLoader } from "./MasterComponent/Overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loginRequest } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { setIsCollapse, SetLoadingOverlay, SetRole } from "./Redux/Action";
import {
  getUserType,
  getMainMenuItems,
  getJwtToken,
} from "./CommonFunctions/ApiCalls";
import "@progress/kendo-theme-default/dist/all.css";
import "@progress/kendo-theme-default/dist/default-ocean-blue-a11y.scss";
import "@progress/kendo-font-icons/dist/index.css";
import BackgroundImg from "./Assets/background.jpg";


function App(props) {
  // const [isCollapse, setIsCollapse] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [openRegistration, setOpenRegistration] = useState(false);
  const { instance, accounts } = useMsal();

  const navigate = useNavigate();

  useEffect(() => {
    if (accounts && accounts.length > 0) {
      getCurrentUserType(accounts[0]?.idTokenClaims?.email_address);
      getSideBarMenu(accounts[0]?.idTokenClaims?.email_address);
    } else {
      getSideBarMenu();
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate(`/${props.userRole.role}`);
    }
  }, [props.userRole.role]);

  const getCurrentUserType = async (email) => {
    try {
      if (email) {
        return await getUserType(email).then((result) => {
          if (result) {
            console.log(email, "req");
            let user = result.key.toLowerCase().replaceAll("_", "-");
            if (result.value !== -1) {
              props.SetRole(true, user, email);
              console.log(user, "user");
            }
            setIsInitialized(true);
            return user;
          }
        });
      }
    } catch (e) {
      console.log(e, "err");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("logOutTime") && props.userRole.isLogin) {
      setTimeout(() => {
        handleLogOut();
        toast.error("Session Expired!!!");
      }, new Date(localStorage.getItem("logOutTime")) - new Date());
    }
  }, [localStorage.getItem("logOutTime")]);

  console.log(
    new Date(localStorage.getItem("logOutTime")) - new Date(),
    "time diff"
  );
  
  const handleLogin = () => {
    props.SetLoadingOverlay(true);
    instance
      .loginPopup(loginRequest)
      .then(async (value) => {
        console.log(value, "jbj");
        if (value) {
          var result = await getJwtToken(value.idTokenClaims.email_address);
          if (localStorage.getItem("token")) {
            localStorage.removeItem("token");
          }
          if (localStorage.getItem("token")) {
            localStorage.removeItem("logOutTime");
          }
          localStorage.setItem("token", result);
          localStorage.setItem(
            "logOutTime",
            new Date(new Date().getTime() + 1 * 59 * 60 * 1000)
          );
          let user = await getCurrentUserType(
            value.idTokenClaims.email_address
          );
          getSideBarMenu(value.idTokenClaims.email_address);
          props.SetLoadingOverlay(false);
          if (user === "invalid") {
            toast.error("User not found. Please Register !!!");
            setOpenRegistration(true);
            // handleLogOut();
          } else {
            navigate(`/${user}`);
          }
          console.log(user, "uuuuuuu");
        }
      })
      .catch((e) => {
        console.log(e, "err");
        props.SetLoadingOverlay(false);
        handleLogOut();
        if (!e.toString().includes("user_cancelled"))
          toast.error(`Something went wrong Please try again later !!! ${e}`);
      });
  };

  const handleLogOut = async () => {
    props.SetLoadingOverlay(true);
    instance.initialize();
    console.log("logout");
    await instance.logoutRedirect({ account: instance.getActiveAccount() });
    if (localStorage.getItem("token")) {
      localStorage.removeItem("token");
    }
    if (localStorage.getItem("logOutTime")) {
      localStorage.removeItem("logOutTime");
    }
    props.SetRole(false, "", "");
    props.SetLoadingOverlay(false);
  };

  const getSideBarMenu = async (email = null) => {
    props.SetLoadingOverlay(true);
    await getMainMenuItems(email)
      .then((data) => {
        if (data && data.length > 0) {
          setMenuList(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
    props.SetLoadingOverlay(false);
  };
  console.log(accounts, "acc");

  return (
    <>
      {isInitialized && (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{ width: "auto" }}
          />
          {props.isLoading && <CommonLoader />}
          <Header accounts={accounts} handleLogOut={handleLogOut} />
          <div
            className="absolute top-[119px] flex w-full"
            style={{
              height: "calc(100% - 119px)",
              backgroundImage: `url(${BackgroundImg})`,
            }}
          >
            <div className={`${props.isCollapse ? "w-56" : "w-12"} fixed h-full`}>
              <SideBar
                // isCollapse={props.isCollapse}
                // setIsCollapse={props.setIsCollapse}
                accounts={accounts}
                menuList={menuList}
              />
            </div>

            <div
              style={
                props.isCollapse
                  ? { width: "calc(100% - 224px)" }
                  : { width: "calc(100% - 48px)" }
              }
              className={`p-5 absolute h-full ${
                props.isCollapse ? "left-[224px]" : "left-[48px]"
              } h-full`}
            >
              {accounts &&
              accounts.length > 0 &&
              props.userRole.isLogin &&
              props.userRole.role ? (
                <div className="appPageContainer">
                  <Routing />
                </div>
              ) : (
                <div className="landingPageContainer">
                  <Login handleLogin={handleLogin} setOpenRegistration={setOpenRegistration} openRegistration={openRegistration} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  console.log(state, "accstate");
  return {
    userRole: state.userRole,
    isLoading: state.isLoading,
    isCollapse: state.isCollapse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetRole: (isLogin, role, email) => dispatch(SetRole(isLogin, role, email)),
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
    setIsCollapse: (isCollapse) => dispatch(setIsCollapse(isCollapse))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
