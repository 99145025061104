import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CreditCardForm from "./CreditCardForm";
import {
  getAgreementStatus,
  getBlinkIdentification,
  getCoiningDieProof,
  getCoiningDieTemplate,
  uploadCoingdie,
  uploadCoingdieArtwork,
} from "../../CommonFunctions/ApiCalls";
import html2canvas from "html2canvas";
import DownloadIcon from "@mui/icons-material/Download";
import DoneIcon from "@mui/icons-material/Done";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import UploadIcon from "@mui/icons-material/Upload";
import CommonModel from "../../MasterComponent/CommonModel";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ConfirmBox from "../../MasterComponent/ConfirmBox";
import { toast } from "react-toastify";
import { SetLoadingOverlay } from "../../Redux/Action";
import ReplyIcon from "@mui/icons-material/Reply";
import { handleDownloadImage } from "../../CommonFunctions/CommonMethods";

const NewCoiningDie = (props) => {
  const [isOpen, setIsOpen] = useState({ open: false, type: null });
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const [coiningDieTemplate, setCoiningDieTemplate] = useState({});
  const [addNewText, setAddNewText] = useState("");
  const [textLength, setTextLength] = useState("12");
  const [radioSelected, setRadioSelected] = useState("1");
  const [artWork, setArtWork] = useState(null);
  const [NextFile, setNextFile] = useState(null);
  const [coiningDieCustome, setCoiningDieCustome] = useState(null);
  const [completedCoiningDie, setCompletedCoiningDie] = useState(null);
  const [agreementStatus, setAgreementStatus] = useState({});

  const imgRef = useRef(null);
  const rightImgRef = useRef(null);
  const parentImgRef = useRef(null);
  const parentFabricImgRef = useRef(null);
  const ToCaptureRef = useRef();
  const ref = useRef(null);

  const { editor, onReady } = useFabricJSEditor();

  const navigate = useNavigate();

  let agreementId = useParams("agreementId");

  useEffect(() => {
    // GetCoiningDieTemplateApi();
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (props.userRole.role === "internal") {
      setIsOpen({ open: false, type: null });
      GetCoiningDieTemplateApi(76);
    }
    if (
      props.userRole.role === "end-user" ||
      props.userRole.role === "customer"
    ) {
      console.log(props.userRole === "end-user", "userRole");
      getAgreementStatusApi();
    }
    getCoiningDieCompletedImage();
  }, []);

  useEffect(() => {
    if (coiningDieCustome && coiningDieCustome !== null) {
      submitCustomeCoiningDie();
    }
  }, [coiningDieCustome]);

  useEffect(() => {
    console.log(editor, "canvas");
    if (!editor || !fabric) {
      return;
    }

    if (!editor.canvas.__eventListeners["mouse:wheel"]) {
      editor.canvas.on("mouse:wheel", function (opt) {
        var delta = opt.e.deltaY;
        var zoom = editor.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });
    }

    if (!editor.canvas.__eventListeners["mouse:down"]) {
      editor.canvas.on("mouse:down", function (opt) {
        var evt = opt.e;
        if (evt.ctrlKey === true) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = evt.clientX;
          this.lastPosY = evt.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:move"]) {
      editor.canvas.on("mouse:move", function (opt) {
        if (this.isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });
    }

    if (!editor.canvas.__eventListeners["mouse:up"]) {
      editor.canvas.on("mouse:up", function (opt) {
        // on mouse up we want to recalculate new interaction
        // for all objects, so we call setViewportTransform
        this.setViewportTransform(this.viewportTransform);
        this.isDragging = false;
        this.selection = true;
      });
    }

    editor.canvas.renderAll();
    setTimeout(() => {
      if (editor && editor.canvas) {
        if (
          imgRef &&
          imgRef.current?.clientWidth &&
          imgRef.current?.clientHeight
        ) {
          editor.canvas.setWidth(imgRef.current.clientWidth - 14);
          editor.canvas.setHeight(imgRef.current.clientHeight * 0.63);
        }
        editor.canvas.renderAll();
      }
    }, 1000);
  }, [editor]);

  useEffect(() => {
    handleResize();
  }, [props.isCollapse]);

  useEffect(() => {
    console.log(editor?.canvas, radioSelected, imageIsLoaded, "radioSelected");
    if (radioSelected === "1" && imageIsLoaded && editor?.canvas) {
      handleAddText();
    }
  }, [imageIsLoaded, editor?.canvas]);

  console.log(editor?.canvas, "editor");

  const getAgreementStatusApi = async () => {
    props.SetLoadingOverlay(true);
    await getAgreementStatus(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setAgreementStatus(data);
          if (!data.isFinished) {
            if (data.isCoiningDieComplete) {
              setIsOpen({ open: true, type: "coiningDieRedesign" });
            } else {
              setIsOpen({ open: true, type: "coiningDie" });
            }
          }
        }
      })
      .catch((e) => console.log(e));
    props.SetLoadingOverlay(false);
  };

  const submitCustomeCoiningDie = async () => {
    props.SetLoadingOverlay(true);
    if (artWork) {
      let uploadArtWork = await handleUploadCoingdieArtwork();
      console.log(uploadArtWork, "uploadArtWork");
      if (uploadArtWork) {
        let uploadCdie = await handleUploadCoingdie();
        console.log(uploadCdie, "uploadCdie");
        if (uploadCdie) {
          navigate(
            `/${props.userRole.role}/agreement/${agreementId.agreementId}`
          );
        }
      }
    } else {
      let uploadCdie = await handleUploadCoingdie();
      console.log(uploadCdie, "uploadCdie");
      if (uploadCdie) {
        navigate(
          `/${props.userRole.role}/agreement/${agreementId.agreementId}`
        );
      }
    }
    props.SetLoadingOverlay(false);
  };

  const handleResize = () => {
    console.log("resize", imgRef?.current?.clientHeight, editor);
    setTimeout(() => {
      if (editor && editor.canvas) {
        if (
          imgRef &&
          imgRef.current?.clientWidth &&
          imgRef.current?.clientHeight
        ) {
          editor.canvas.setWidth(imgRef.current.clientWidth - 14);
          editor.canvas.setHeight(imgRef.current.clientHeight * 0.63);
        }
        editor.canvas.renderAll();
      }
    }, 1000);
  };

  const getModelBody = () => {
    if (isOpen.type === "coiningDie") {
      return (
        <div className="ml-5 mt-3">
          <div>
            <div className="font-semibold">
              Why do you have to purchase a coining die ?
            </div>
            <div className="ml-3 text-sm">
              Coining Dies are a part of the key control that we offer. When we
            </div>
            <span className="ml-3 text-sm">
              Coin your key blanks with your coining die, we are able to
              identify{" "}
            </span>
          </div>
          <div>
            <div className="font-semibold mt-5">
              What would you like coined on your Key Blanks?
            </div>
            <FormControl className="w-full">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(e) => {
                  setRadioSelected(e.target.value);
                }}
                value={radioSelected}
              >
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "900px",
                    },
                  }}
                  value={1}
                  control={<Radio size="small" />}
                  label="Blind Identification - I want coining to not identify my facility"
                />
                <label className="text-sm ml-5">
                  Using the coining die number as your coining ensures that any
                  persons cannot easily identify your facility
                </label>
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontSize: "16px",
                      fontWeight: "900px",
                    },
                  }}
                  value={2}
                  control={<Radio size="small" />}
                  label="Marketing - I want custom artwork"
                />
                <label className="text-sm ml-5">
                  Using custom artwork allows persons to identify your facility
                  in the event of a lost key
                </label>
              </RadioGroup>
            </FormControl>
            <div className="flex flex-row">
              <div className="font-semibold mt-5 ml-10 w-1/2">
                Key Bow Size?
              </div>
              <FormControl className="w-full mt-4">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    setTextLength(e.target.value);
                  }}
                  value={textLength}
                >
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "900px",
                      },
                    }}
                    value={12}
                    control={<Radio size="small" />}
                    label="Small (12 Characters)"
                  />
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontSize: "14px",
                        fontWeight: "900px",
                      },
                    }}
                    value={14}
                    control={<Radio size="small" />}
                    label="Large (14 Characters)"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      );
    }
    // else if (isOpen.type === "prepay") {
    //   return (
    //     <div className="mx-5 mt">
    //       <div className="mb-5">
    //         Prepaying for your coining die will help ensure we have it once your
    //         order hits the factory floor
    //       </div>
    //       <CreditCardForm setIsOpen={setIsOpen} />

    //       <div className="text-center m-3 text-size">
    //         This option may cause longer lead times on your order
    //       </div>
    //       <div className="text-center m-3">
    //         <NavLink
    //           to={
    //             props?.userRole?.role === "end-user"
    //               ? `/end-user/agreement/${agreementId.agreementId}`
    //               : `/customer/agreement/${agreementId.agreementId}`
    //           }
    //         >
    //           <Button
    //             variant="contained"
    //             size="small"
    //             className="button-text-decor"
    //             style={{ position: "relative", bottom: "0" }}
    //             onClick={() => setIsOpen({ open: false, type: null })}
    //           >
    //             Pay Later
    //           </Button>
    //         </NavLink>
    //       </div>
    //     </div>
    //   );
    // }
    else if (isOpen.type === "coiningDieRedesign") {
      return (
        <div className="flex flex-col justify-center items-center p-5">
          <div className="mb-5">Do you want to redesign the Coining Die ?</div>
          <img
            src={completedCoiningDie?.proofURL}
            alt="ResourceImage"
            className="w-[35%] h-full"
          />
        </div>
      );
    }
  };

  const GetCoiningDieTemplateApi = async (bowSize) => {
    props.SetLoadingOverlay(true);
    await getCoiningDieTemplate(bowSize, agreementId.agreementId)
      .then((data) => {
        if (data) {
          setCoiningDieTemplate(data);
          setTimeout(() => {
            setIsOpen({ open: false, type: null });
          }, 200);
        }
      })
      .catch((e) => console.log(e, "erro"));
    props.SetLoadingOverlay(false);
  };

  const handelUploadImage = async (e) => {
    if (e.target) {
      if (artWork) {
        setIsOpen({ open: true, type: "confirm" });
        setNextFile(e.target.files[0]);
      } else {
        const file = e.target.files[0];
        console.log(file, "file");
        if (file) {
          setArtWork(file);
          const reader = new FileReader();
          reader.onload = function (evt) {
            fabric.Image.fromURL(evt.target.result, function (img) {
              img.scaleToWidth(50);
              img.scaleToHeight(50);
              img.set({
                id: "artWork",
                left: 90,
                top: 100,
              });
              editor.canvas.add(img);
            });
          };
          reader.readAsDataURL(file);
        }
      }
      e.target.value = null;
    }
    editor.canvas.renderAll();
  };

  const captureScreenshot = async () => {
    var canvas = await html2canvas(ToCaptureRef.current, {
      useCORS: true,
      backgroundColor: null,
    });
    let file;
    // await canvasPromise.then((canvas) => {
    const canvasbg = document.createElement("canvas");
    canvasbg.width = rightImgRef.current.clientWidth * 3;
    canvasbg.height = rightImgRef.current.clientHeight * 3;
    const ctx = canvasbg.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvasbg.width, canvasbg.height);
    let bgimg = new Image();

    bgimg.onload = function (bgdata) {
      ctx.drawImage(
        bgimg,
        0,
        0,
        bgimg.width,
        bgimg.height,
        0,
        0,
        canvasbg.width,
        canvasbg.height
      );
      var content = canvas.toDataURL("image/png");

      var imgContent = new Image();
      imgContent.onload = async (bata) => {
        ctx.drawImage(
          imgContent,
          0,
          0,
          imgContent.width,
          imgContent.height,
          7 * 3,
          7 * 3,
          ToCaptureRef.current.clientWidth * 3,
          ToCaptureRef.current.clientHeight * 3
        );
        var dataURL = canvasbg.toDataURL("image/png");

        // Create an image element from the data URL
        var img = new Image();
        img.src = dataURL;
        img.download = dataURL;
        // Create a link element
        var a = document.createElement("a");
        // a.innerHTML = "DOWNLOAD";
        a.target = "_blank";
        // Set the href of the link to the data URL of the image
        a.href = img.src;
        // console.log("capture", a, img);
        // Set the download attribute of the link
        a.download = "coiningDie";
        // Append the link to the page
        document.body.appendChild(a);
        // Click the link to trigger the download
        a.click();
        console.log(img, "coiningDieCustome 11");

        file = await base64ToImage(img.src, "coiningDie.png");
        if (
          props.userRole.role === "end-user" ||
          props.userRole.role === "customer"
        ) {
          setCoiningDieCustome(file);
        }
      };
      imgContent.src = content;
    };

    bgimg.crossOrigin = "anonymous";
    bgimg.src =
      textLength === "12"
        ? window.location.origin + "/M4%20Design.svg"
        : window.location.origin + "/M4%20Design%20Small%20Bow.svg"; //coiningDieTemplate.coiningDieRightURL;
  };

  console.log(coiningDieCustome, "coiningDieCustome", artWork);

  const removeSelectedObject = () => {
    let obj = editor.canvas.getActiveObject();
    if (obj && obj.id === "artWork") {
      setArtWork(null);
    }
    editor.canvas.remove(editor.canvas.getActiveObject());
  };

  const base64ToImage = async (base64String, filename) => {
    return await fetch(base64String)
      .then((res) => res.blob())
      .then((blob) => {
        // Create an object URL for the Blob
        const url = URL.createObjectURL(blob);
        return new File([blob], "document.png", { type: "image/png" });
      })
      .catch((err) => console.error("Error converting canvas to file:", err));
  };

  const handleFinish = async () => {
    props.SetLoadingOverlay(true);
    editor.canvas.discardActiveObject();
    editor.canvas.requestRenderAll();
    // props.userRole.role === "internal" && setIsEditEnable(false);
    // : setIsOpen({ open: true, type: "prepay" });
    setTimeout(async () => {
      await captureScreenshot();
    }, 1000);
    props.SetLoadingOverlay(false);
  };

  const handleAddText = () => {
    console.log(addNewText, "addNew");
    const text = new fabric.Text(addNewText, {
      left: 80,
      top: 90,
      fontSize: 16,
      fill: "black",
    });
    editor?.canvas?.add(text);
    setAddNewText("");
  };

  const handleConfirmBox = () => {
    setArtWork(NextFile);
    editor.canvas.getObjects().forEach(function (o) {
      if (o.id == "artWork") {
        editor.canvas.setActiveObject(o);
        editor.canvas.remove(editor.canvas.getActiveObject());
        console.log(o.id, "idddddd");
      }
    });
    const reader = new FileReader();
    reader.onload = function (evt) {
      fabric.Image.fromURL(evt.target.result, function (img) {
        img.scaleToWidth(50);
        img.scaleToHeight(50);
        img.set({
          id: "artWork",
          left: 90,
          top: 100,
        });
        editor.canvas.add(img);
      });
    };
    reader.readAsDataURL(NextFile);

    setIsOpen({ open: false, type: null });
    editor.canvas.renderAll();
  };

  const handleUploadCoingdieArtwork = async () => {
    console.log(artWork, "artwork");
    let result = await uploadCoingdieArtwork(artWork, agreementId.agreementId);

    console.log(result, "result");
    if (result && result !== undefined && result.statusCode === 200) {
      return true;
    } else {
      toast.error("Please try again later!!!");
      return false;
    }
  };

  const handleUploadCoingdie = async () => {
    let result = await uploadCoingdie(
      coiningDieCustome,
      agreementId.agreementId
    );
    console.log(result, "Result");

    if (result && result !== undefined && result.statusCode === 200) {
      toast.success("Updated Successfully !!!");
      return true;
    } else {
      toast.error("Please try again later!!!");
      return false;
    }
  };

  const handleDesignCoiningDieClick = async () => {
    const bowSize = textLength === "12" ? 76 : 83;
    GetCoiningDieTemplateApi(bowSize);
    console.log(radioSelected, "radioSelected");
    if (radioSelected === "1") {
      await getBlinkIdentification(agreementId.agreementId)
        .then((data) => {
          if (data) {
            setAddNewText(data["blindIdentification"]);
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const handleCloseDesignCoiningDie = () => {
    navigate(`/${props.userRole.role}/agreement/${agreementId.agreementId}`);
  };

  const getCoiningDieCompletedImage = async () => {
    await getCoiningDieProof(agreementId.agreementId)
      .then((data) => {
        if (data) {
          setCompletedCoiningDie(data);
        }
      })
      .catch((e) => console.log(e));
  };

  console.log(
    (props.userRole.role === "end-user" && agreementStatus?.isFinished) ||
      (completedCoiningDie && Object.keys(coiningDieTemplate).length > 0),
    completedCoiningDie,
    "coiningDieTemplate",
    props.userRole.role
  );

  return (
    <>
      <div className="grid grid-cols-4 h-full" id="coiningdie-grid" ref={ref}>
        {((props.userRole.role === "end-user" &&
          !agreementStatus?.isFinished) ||
          (props.userRole.role === "customer" &&
            !agreementStatus?.isFinished)) &&
          coiningDieTemplate &&
          Object.keys(coiningDieTemplate).length > 0 && (
            <>
              <div
                className="col-span-3 flex justify-center items-center"
                style={{
                  border: "1px solid #999",
                  borderRadius: "8px",
                  boxShadow: "0 0 8px #999",
                }}
              >
                <div className="w-1/2 flex items-center justify-center">
                  <img
                    ref={imgRef}
                    onLoad={() => {
                      setImageIsLoaded(true);
                    }}
                    src={coiningDieTemplate.coiningDieLeftURL}
                    alt="ResourceImage"
                    className="w-[60%] h-full"
                  />
                </div>
                <div className="w-1/2  h-full flex items-center justify-center object-center">
                  <img
                    src={coiningDieTemplate.coiningDieRightURL}
                    alt="ResourceImage"
                    className="w-[60%]"
                    ref={rightImgRef}
                  />
                </div>
              </div>
              <div
                className="h-[31%] w-[75%] absolute z-10 m-5 -left-5"
                style={{
                  height: imgRef.current
                    ? imgRef.current.clientHeight * 0.63
                    : "",
                }}
                ref={parentImgRef}
              >
                {" "}
                {imageIsLoaded && (
                  <div
                    className="left-1/2 relative h-full shadow-xl bg-transparent"
                    style={{
                      width: imgRef.current
                        ? imgRef.current.clientWidth - 14 + "px"
                        : "",
                      left: imgRef.current
                        ? parentImgRef.current.clientWidth / 2 +
                          (parentImgRef.current.clientWidth / 2 -
                            imgRef.current.clientWidth) /
                            2 +
                          5
                        : "",
                      top:
                        imgRef.current && ref
                          ? (ref.current.clientHeight -
                              imgRef.current.clientHeight) /
                              2 -
                            15
                          : "",
                      height: imgRef.current
                        ? imgRef.current.clientHeight * 0.63
                        : "",
                      borderEndStartRadius: "33px",
                      borderEndEndRadius: "33px",
                    }}
                    ref={ToCaptureRef}
                  >
                    <FabricJSCanvas
                      ref={parentFabricImgRef}
                      onReady={onReady}
                    />
                  </div>
                )}
              </div>
              <div className="m-3">
                {radioSelected !== "1" && (
                  <>
                    <div className="mb-3">
                      <IconButton component="label">
                        <UploadIcon
                          fontSize="large"
                          className="cursor-pointer"
                          color="primary"
                        />
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handelUploadImage(e);
                          }}
                          hidden
                        />
                      </IconButton>
                      <label className="text-size">Upload Custom Artwork</label>
                    </div>
                    <div className="ml-2 mb-3">
                      <input
                        type="text"
                        className={
                          "border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[29px] w-32 text-size mr-2"
                        }
                        onChange={(e) => setAddNewText(e.target.value)}
                        value={addNewText}
                        maxLength={textLength}
                      />
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          className="button-text-decor"
                          onClick={() => handleAddText()}
                        >
                          Add Text
                        </Button>
                      </span>
                    </div>
                    <div className="ml-2">
                      <IconButton>
                        <DeleteOutlineIcon
                          fontSize="large"
                          color="error"
                          className="cursor-pointer"
                          onClick={removeSelectedObject}
                        />
                      </IconButton>
                      <label className="text-size">
                        Delete Selected Element
                      </label>
                    </div>
                  </>
                )}
                <div
                  className="flex items-end justify-between"
                  style={{ height: "calc(100% - 240px)" }}
                >
                  <Button
                    variant="outlined"
                    size="medium"
                    color="error"
                    style={{
                      position: "relative",
                      bottom: "0",
                      marginBottom: "16px",
                    }}
                    onClick={() =>
                      navigate(
                        `/${props.userRole.role}/agreement/${agreementId.agreementId}`
                      )
                    }
                  >
                    cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    style={{
                      position: "relative",
                      bottom: "0",
                      marginBottom: "16px",
                    }}
                    onClick={() => handleFinish()}
                  >
                    Finished
                  </Button>
                </div>
                <p className="text-sm">
                  **No color will be displayed on the keybow. The design will be
                  stamped into the nickle silver key bow.**
                </p>
              </div>
            </>
          )}
        {(props.userRole.role === "internal" ||
          (props.userRole.role === "end-user" && agreementStatus?.isFinished) ||
          (props.userRole.role === "customer" &&
            agreementStatus?.isFinished)) &&
          completedCoiningDie &&
          Object.keys(completedCoiningDie).length > 0 && (
            <>
              <div
                className="col-span-3 flex justify-center items-center"
                style={{
                  border: "1px solid #999",
                  borderRadius: "8px",
                  boxShadow: "0 0 8px #999",
                }}
              >
                <div className="w-1/2 flex items-center justify-center">
                  <img
                    src={completedCoiningDie.proofURL}
                    alt="ResourceImage"
                    className="w-[60%] h-full"
                  />
                </div>
              </div>
              <div className="flex justify-center items-start gap-4 mt-9">
                <Button
                  className="button-text-decor"
                  variant="outlined"
                  startIcon={<ReplyIcon />}
                  onClick={
                    props.userRole.role === "internal"
                      ? () =>
                          navigate(
                            `/${props.userRole.role}/view-agreement/${agreementId.agreementId}`
                          )
                      : () =>
                          navigate(
                            `/${props.userRole.role}/agreement/${agreementId.agreementId}`
                          )
                  }
                >
                  Back
                </Button>
                <Button
                  className="button-text-decor"
                  variant="contained"
                  startIcon={<DownloadIcon />}
                  onClick={() =>
                    handleDownloadImage(
                      completedCoiningDie.proofURL,
                      "CoiningDie"
                    )
                  }
                >
                  Download
                </Button>
              </div>
            </>
          )}
      </div>
      <CommonModel
        isOpen={isOpen.open && !isOpen.type.includes("confirm")}
        onCloseModel={() => {
          isOpen.type !== "coiningDie" && isOpen.type !== "coiningDieRedesign"
            ? setIsOpen({ open: false, type: null })
            : handleCloseDesignCoiningDie();
        }}
        header={
          isOpen.type === "coiningDie"
            ? "Coining Die"
            : isOpen.type === "coiningDieRedesign"
            ? "Please Confirm"
            : "Prepay Coining Die Charge"
        }
        body={getModelBody()}
        size="500px"
        onSubmit={() => {
          isOpen.type === "coiningDie"
            ? handleDesignCoiningDieClick()
            : setIsOpen({ open: true, type: "coiningDie" });
        }}
        footer={{
          isVisible: isOpen.type === "prepay" ? false : true,
          isSubmitVisible: isOpen.type === "prepay" ? false : true,
          iscancelVisible: isOpen.type === "prepay" ? false : true,
        }}
        buttonName={{
          submit: isOpen.type === "coiningDie" ? "Design Coining Die" : "Yes",
          cancel: isOpen.type === "coiningDie" ? "Cancel" : "No",
        }}
        height={isOpen.type === "prepay" ? "75%" : "85%"}
      />
      <ConfirmBox
        isOpen={isOpen.open && isOpen.type.includes("confirm")}
        onSubmitModel={() => handleConfirmBox()}
        onCloseModel={() => setIsOpen({ open: false, type: null })}
        message={"Do you want to Replace the existing Art Work?"}
        type="update"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    isCollapse: state.isCollapse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCoiningDie);
